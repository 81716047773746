import { useContext } from "react"
import { AppContext } from "../App"
import Redirect from '../views/Redirect';

export default function MainBody(props) {

  const appContext = useContext(AppContext);


  return (
    <div 
      className="MainBody mNoScrollBar"
      data-sidebar-open={appContext.isSideBarOpen}
    >
      <Redirect/>
    </div>
  )
}