import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import logo from '../assets/logo.png';
import logo_worded_only from '../assets/logo_worded_only.png';
import { callApi, getCookie, getInlineLoader, setCookie } from "../Helpers";
import { MdLock, MdLogin, MdOutlinePhonelinkLock, MdPassword, MdPerson, MdPhone, MdPhoneAndroid, MdPhonelinkLock, MdPhoneLocked } from "react-icons/md";
import { GiLovers, GiTalk } from "react-icons/gi";
import { FaUserDoctor } from "react-icons/fa6";
import {
  STR_APP_NAME,
  STR_CHANGE_LANGUAGE,
  STR_CHANGE,
  STR_CONFIRM,
  STR_CONTINUE,
  STR_ENTER,
  STR_ENTER_EMAIL_ADDRESS,
  STR_ENTER_OTP,
  STR_ENTER_YOUR_PHONE_NUMBER,
  STR_FORGOT_PASSWORD,
  STR_GET_STARTED,
  STR_HEALTH,
  STR_INVALID_CONFIRM_PASSWORD,
  STR_INVALID_EMAIL,
  STR_INVALID_OTP,
  STR_INVALID_PASSWORD,
  STR_INVALID_PHONE,
  STR_PASSWORD,
  STR_PLEASE_WAIT,
  STR_RELATIONSHIPS,
  STR_RESEND_OTP,
  STR_STORIES,
  STR_USE_EMAIL,
  STR_USE_PHONE,
  STR_WELCOME,
  STR_LOGIN_TO_CONTINUE,
  STR_LOGIN_NOW
} from "../Strings";
import MobileInterface from "../MobileInterface";

export default function Login({ formOnly = false }) {

  const appContext = useContext(AppContext);

  function openAcademy() {
		if(window.MobileInterface) {
			MobileInterface.openLink("https://play.google.com/store/apps/details?id=com.tanzaniteskills.academy");
		} else {
			window.location.href = "https://learn.tanzaniteskills.ac.tz/";
		}
	}
  
	function openVitabu() {
		if(window.MobileInterface) {
			MobileInterface.openLink("https://play.google.com/store/apps/details?id=com.soma.vitabu");
		} else {
			window.location.href = "https://vitabu.somavitabu.co.tz/";
		}
	}

  return (
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<img
						src={logo}
						style={{
							width: "150px"
						}}
					/>
					<hr/>
				</div>

				<div className="col-md-6">
					<div className="card mShadow4">
						<div className="card-body">
							
							<h6
								style={{
									fontSize: "18px"
								}}
								className=""
							>
								Sasa Unaweza kusoma vitabu vyote vipya na ulivonunua zamani kupitia SOMAVITABU
							</h6>

							<div className="text-end mt-4">
								<button
									onClick={openVitabu}
									className="btn btn-dark"
								>
									Tembelea SOMAVITABU
								</button>
							</div>
							
						</div>
					</div>
				</div>

				<div className="col-md-6">
					<div className="card">
						<div className="card-body">
							
							<h6
								style={{
									fontSize: "18px"
								}}
								className=""
							>
								Pia unaweza kupata Mafunzo yote (Kozi, Makala na Masterclasses) kupitia jukwaa la TANZANITE SKILLS ACADEMY - TSA
							</h6>

							<div className="text-end mt-4">
								<button
									onClick={openAcademy}
									className="btn btn-primary"
								>
									Tembelea TANZANITE <br/> SKILLS ACADEMY
								</button>
							</div>
							
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}